import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'la-filter-autocomplete',
  templateUrl: './filter-autocomplete.component.html',
  styleUrl: './filter-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterAutocompleteComponent),
      multi: true,
    },
  ],
})
export class FilterAutocompleteComponent implements ControlValueAccessor {
  @Input() options: { name: string; id: string }[] = [];

  @Output() search = new EventEmitter<string>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  searchControl = new FormControl('');

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: string): void {
    this.searchControl.setValue(obj);
    this.search.emit(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  filter() {
    if (!this.input?.nativeElement.value) {
      this.searchControl.setValue('');
      this.search.emit('');
    }

    if (this.input?.nativeElement.value !== this.searchControl.value) {
      this.search.emit(this.input?.nativeElement.value);
    }
  }

  optionSelected($event: MatAutocompleteSelectedEvent) {
    this.onChange($event.option.value);

    if (this.input?.nativeElement) {
      this.input.nativeElement.value = this.display($event.option.value);
    }
  }

  display(event: string) {
    const option = this.options?.find((option) => option.id === event);
    return option ? option.name : '';
  }
}

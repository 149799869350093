import { createAction, props } from '@ngrx/store';

import { ArchivedLayersParams, PlanNode } from '@core/api';
import { ImportedDuplicateData, ImportedFilesData, ImportedPickerData, ImportedRPAData } from '../../models/new-plan.models';
import { TreeNode, TreeSelection } from '../../models/plans-tree.model';

/**
 * New Plan Actions
 * ----------------------------------------------
 */

export const openNewPlanMenu = createAction('[Map/LHP] Open New Plan Menu');

export const openCreatePlanStepperDialog = createAction(
  '[Map/LHP] Open New Plan Dialog',
  props<{ mode: 'CHOOSE_TEMPLATE' | 'IMPORT_DATA'; files?: FileList }>()
);

export const openCreateDownloadPlan = createAction('[Map/LHP] Open Create Download Plan');

export const createBlankPlan = createAction('[Map/LHP] Create Blank Plan');

export const createEmptyPlan = createAction('[Map/LHP] Create Empty Plan', props<{ name: string; subtype: string }>());

export const createPlanFromPicker = createAction(
  '[Map/LHP/Plan] Create Plan From Picker',
  props<{ name: string; subtype: string; importData: ImportedPickerData }>()
);

export const createPlansFromRPA = createAction(
  '[Map/LHP/Plan] Create Plan From RPA',
  props<{ name: string; subtype: string; importData: ImportedRPAData }>()
);

export const createPlansFromFiles = createAction(
  '[Map/LHP/Plan] Create Plan From Files',
  props<{ name: string; subtype: string; importData: ImportedFilesData }>()
);

export const createPlanFromExistingPlan = createAction(
  '[Map/LHP/Plan] Create Plan From Existing Plan',
  props<{ name: string; subtype: string; importData: ImportedDuplicateData }>()
);

export const cancelPlanCreation = createAction('[Map/LHP/Plan] Cancel Plan Creation');

/**
 * Tree Generic Actions
 * ----------------------------------------------
 */

export const selectTreeNode = createAction('[Map/LHP] Select Tree Node', props<TreeSelection>());

export const dragAndDropNodes = createAction(
  '[Map/LHP] Drag & Drop Nodes',
  props<{ sourcePaths: string[]; targetPath: string; action: string }>()
);

export const droppedNodes = createAction('[Map/LHP] Dropped Nodes', props<{ plans: PlanNode[] }>());

/**
 * Tree Folder Actions
 * ----------------------------------------------
 */

export const deleteFolder = createAction('[Map/LHP] Delete Folder', props<{ folderId: string }>());

export const publishFolder = createAction('[Map/LHP] Publish Folder', props<{ folderId: string }>());

export const unpublishFolder = createAction('[Map/LHP] Unpublish Folder', props<{ folderId: string }>());

export const showFolder = createAction('[Map/LHP] Show Folder', props<{ folderId: string }>());

export const hideFolder = createAction('[Map/LHP] Hide Folder', props<{ folderId: string }>());

export const createFolder = createAction('[Map/LHP] Create Folder', props<{ name: string }>());

export const renameFolder = createAction('[Map/LHP] Rename Folder', props<{ id: string; name: string }>());

export const lockFolder = createAction('[Map/LHP] Lock Folder', props<{ id: string }>());

export const unlockFolder = createAction('[Map/LHP] Unlock Folder', props<{ id: string }>());

export const openRenameFolderDialog = createAction('[Map/LHP] Open Rename Folder Dialog', props<{ id?: string; name?: string }>());

export const cancelRenameFolder = createAction('[Map/LHP] Cancel Rename Folder');

/**
 * Tree Plan Actions
 * ----------------------------------------------
 */

export const openSelectAllFeaturesDialog = createAction('[Map/LHP] Open Select All Features Dialog', props<{ planId: string }>());

export const openPlanDetailsDialog = createAction('[Map/LHP] Open Plan Details Dialog', props<{ planId: string }>());

export const renamePlan = createAction('[Map/LHP] Rename Plan', props<{ planId: string; name: string }>());

export const zoomToLayer = createAction('[Map/LHP] Zoom To Plan', props<{ id: string }>());

export const duplicatePlan = createAction('[Map/LHP] Duplicate Plan', props<{ id: string }>());

export const showPlan = createAction('[Map/LHP] Show Plan', props<{ id: string }>());

export const hidePlan = createAction('[Map/LHP] Hide Plan', props<{ id: string }>());

export const publishPlan = createAction('[Map/LHP] Publish Plan', props<{ planId: string }>());

export const unpublishPlan = createAction('[Map/LHP] Unpublish Plan', props<{ planId: string }>());

export const lockPlan = createAction('[Map/LHP] Lock Plan', props<{ planId: string }>());

export const unlockPlan = createAction('[Map/LHP] Unlock Plan', props<{ planId: string }>());

export const exportPlan = createAction('[Map/LHP] Export Plan', props<{ planId: string }>());

export const archivePlan = createAction('[Map/LHP] Archive Plan', props<{ id: string; name: string }>());

export const fetchLandDeclaration = createAction('[Map/LHP] Fetch Land Declaration', props<{ mapId: string }>());

/**
 * Tree Parcel Actions
 * ----------------------------------------------
 */

export const renameParcel = createAction('[Map/LHP] Rename Parcel', props<{ node: TreeNode }>());

export const showParcel = createAction('[Map/LHP] Show Parcel', props<{ node: TreeNode }>());

export const hideParcel = createAction('[Map/LHP] Hide Parcel', props<{ node: TreeNode }>());

/**
 * Tree Feature Actions
 * ----------------------------------------------
 */

export const toggleSelectedFeaturesVisibility = createAction('[Map/LHP] Toggle Selected Features Visibility', props<{ node: TreeNode }>());

export const selectSimilarFeatures = createAction('[Map/LHP] Select Similar Features', props<{ node: TreeNode }>());

export const zoomToFeature = createAction('[Map/LHP] Zoom To Feature', props<{ node: TreeNode }>());

export const deleteFeature = createAction('[Map/LHP] Delete Feature', props<{ node: TreeNode }>());

export const moveFeature = createAction('[Map/LHP] Move Feature To Parcel', props<{ node: TreeNode }>());

/**
 * New Folder Actions
 * ----------------------------------------------
 */

export const openCreateFolderDialog = createAction('[Map/LHP] Open Create Folder Dialog');

export const cancelCreateFolder = createAction('[Map/LHP] Cancel Create Folder');

/**
 * Photos Actions
 * ----------------------------------------------
 */

export const showPhotos = createAction('[Map/LHP] Show Photos');

export const hidePhotos = createAction('[Map/LHP] Hide Photos');

/**
 * Archived Plans
 */
export const openArchivedPlansDialog = createAction('[Map/LHP] Open Archived Plans Dialog');

export const closeArchivedPlansDialog = createAction('[Map/LHP] Close Archived Plans Dialog');

export const fetchArchivedPlans = createAction('[Map/LHP] Fetch Archived Plans');

export const setArchivedPlansParams = createAction('[Map/LHP] Set Archived Plans Params', props<{ params: ArchivedLayersParams }>());

export const restorePlan = createAction('[Map/LHP] Restore Plan', props<{ planId: string }>());
